import {MutableRefObject, useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import useCurrentContext from "../../contexts/ContextContext";
import useCurrentUser from "../../contexts/UserContext";
import {TextField} from "../../components/RightPane/RightPane";
import useRest from "../../utils/rest";
import IconInfo from "../../img/icon-info";

import "./Newsletter.scss";

/**
 * @param {Function} onHide
 * @param {Function} onSuccess
 * @returns {JSX.Element}
 * @constructor
 */

const Newsletter = ({onHide, onSuccess}) => {
    const { t } = useTranslation(['common', 'users']);
    const restService = useRest();
    const currentContext = useCurrentContext();
    const currentUser = useCurrentUser();

    const ref: MutableRefObject<HTMLDivElement> = useRef();

    const [agreement: boolean, setAgreement: Function<boolean>] = useState(false);
    const [firstName: string, setFirstName: Function<string>] = useState('');

    useEffect(() => {
        setTimeout(() => {
            ref.current.classList.add('visible');
        }, 10);
    }, []);

    const transitionHandler = (e: TransitionEvent) => {
        if (e.propertyName !== 'transform') {
            return;
        }
        if (!ref.current.classList.contains('visible')) {
            onHide && onHide();
        }
    };

    const onConfirm = async () => {
        if ((!currentUser.first_name && firstName.length === 0) || !agreement) return;
        if (firstName.length > 0) {
            const response = await restService('/account', 'PUT', JSON.stringify({first_name: firstName}));
            if (response && response.first_name) {
                window.dispatchEvent(new CustomEvent('user_data_update', {detail: response}));
            }
            console.debug('Newsletter::updating user first_name', response);
        }

        try {
            const group = currentContext.type === 'rbb_dispatcher' ? 'rbb' : 'click2open';
            const response = await restService(`/account/newsletter`, 'POST', {group: group})
            toast.success(t('SUBSCRIBE_SUCCESS'));
            ref.current.classList.remove('visible');
            onSuccess && onSuccess();
            onHide && onHide();
            console.debug('Newsletter::onConfirm', response);
        } catch (error) {
            toast.error(t('SUBSCRIBE_ERROR'));
            console.debug('Newsletter::onConfirm', error);
        }
    }

    return <div
        ref={ref}
        id="newsletter_modal"
        className="modal delete newsletter"
        onTransitionEnd={transitionHandler}
    >
        <header>{t('SUBSCRIBE')}</header>
        <main>
            {!currentUser.first_name && <TextField
                id="first_name"
                name="first_name"
                label={t('users:FIRST_NAME')}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value.trim())}
                required={true}
            />}
            <div>
                <input
                    type="checkbox"
                    id="agreement"
                    value={agreement}
                    onChange={(e) => setAgreement(e.target.checked)}
                />
                <label htmlFor="agreement">{t('NEWSLETTER_AGREEMENT')}</label>
                <span title={t('NEWSLETTER_INFO')}><IconInfo/></span>
            </div>
        </main>
        <footer>
            <button onClick={() => {ref.current.classList.remove('visible')}}>
                {t('common:CANCEL')}
            </button>
            <button onClick={onConfirm} disabled={!agreement} className="green">
                {t('common:SAVE')}
            </button>
        </footer>
    </div>
}

export default Newsletter;
