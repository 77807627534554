import React, {useEffect, useRef, useState, MutableRefObject} from "react";
import {useTranslation} from "react-i18next";

import "./Help.scss"
import IconChat from "../../img/icon-chat";
import IconDocument from "../../img/icon-document";
import IconSupport from "../../img/icon-support";
import IconChevronDoubleUp from "../../img/icon-chevron-double-up";
import {useVersion} from "../../utils/hooks";
import IconDocumentMulti from "../../img/icon-document-multi";
import extractChanges from "../ChangeLog/extractChanges";
import ChangeLogPopup from "../ChangeLog/parts/ChangeLogPopup";
import useCurrentContext from "../../contexts/ContextContext";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Help() {
    const { t } = useTranslation(['help', 'common']);
    const version = useVersion(true);
    const currentContext = useCurrentContext();
    const [changes: Array, setChanges: Function<Array>] = useState([]);
    const changesRef: MutableRefObject<HTMLFormElement> = useRef();

    // setup title
    useEffect(() => {
        if (currentContext.type === 'rbb_dispatcher') {
            document.title = `${t('common:RBB')} :: ${t('HELP')}`;
        } else {
            document.title = 'Click2Open :: ' + t('HELP');
        }
    }, [t, currentContext.type]);

    function showChangeLog(e: Event) {
        e.preventDefault();
        console.debug('Help() => Load ChangeLog...');

        let data = extractChanges("0.0.0", version);
        data.reverse();
        setChanges(data);
        changesRef.current.classList.add('visible');
    }

    function closeChangeLog(e: Event) {
        e.preventDefault();
        changesRef.current.classList.remove('visible');
    }

    return <>
        <ChangeLogPopup popupRef={changesRef} changes={changes} onClose={closeChangeLog}/>
        <header id="pageHeader">
            <div className="group">{t('HELP')}</div>
        </header>
        <main id="help" className="columns">
            <div>
                <div className="group">
                    <div className="title">
                        <IconChat/>
                        {t('FAQ')}
                    </div>
                    {currentContext.type !== 'rbb_dispatcher' && <div className="faq">
                        <div className="item">
                            <div className="question">{t('FAQ_1_Q')}</div>
                            <div className="answer">{t('FAQ_1_A')}</div>
                        </div>
                        <div className="item">
                            <div className="question">{t('FAQ_2_Q')}</div>
                            <div className="answer">
                                {t('FAQ_2_A')}
                                <ul>
                                    <li>{t('FAQ_2_A_1')}</li>
                                    <li>{t('FAQ_2_A_2')}</li>
                                    <li>{t('FAQ_2_A_3')}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="item">
                            <div className="question">{t('FAQ_3_Q')}</div>
                            <div className="answer">{t('FAQ_3_A')}</div>
                        </div>
                        <div className="item">
                            <div className="question">{t('FAQ_4_Q')}</div>
                            <div className="answer">{t('FAQ_4_A')}</div>
                        </div>
                    </div>}
                    {currentContext.type === 'rbb_dispatcher' && <div className="faq">
                        <div className="item">
                            <div className="question">{t('FAQ_RBB_1_Q')}</div>
                            <div className="answer">
                                {t('FAQ_RBB_1_A')}
                                <ul>
                                    <li>{t('FAQ_RBB_1_A_1')}</li>
                                    <li>{t('FAQ_RBB_1_A_2')}</li>
                                    <li>{t('FAQ_RBB_1_A_3')}</li>
                                    <li>{t('FAQ_RBB_1_A_4')}</li>
                                    <li>{t('FAQ_RBB_1_A_5')}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="item">
                            <div className="question">{t('FAQ_RBB_2_Q')}</div>
                            <div className="answer">{t('FAQ_RBB_2_A')}</div>
                        </div>
                        <h3>{t('common:DISPATCHER')}</h3>
                        <div className="item">
                            <div className="question">{t('FAQ_RBB_3_Q')}</div>
                            <div className="answer">{t('FAQ_RBB_3_A')}</div>
                        </div>
                        <div className="item">
                            <div className="question">{t('FAQ_RBB_4_Q')}</div>
                            <div className="answer">{t('FAQ_RBB_4_A')}</div>
                        </div>
                        <div className="item">
                            <div className="question">{t('FAQ_RBB_5_Q')}</div>
                            <div className="answer">{t('FAQ_RBB_5_A')}</div>
                        </div>
                        <h3>{t('common:MANAGER')}</h3>
                        <div className="item">
                            <div className="question">{t('FAQ_RBB_6_Q')}</div>
                            <div className="answer">{t('FAQ_RBB_6_A')}</div>
                        </div>
                        <div className="item">
                            <div className="question">{t('FAQ_RBB_7_Q')}</div>
                            <div className="answer">{t('FAQ_RBB_7_A')}</div>
                        </div>
                        <div className="item">
                            <div className="question">{t('FAQ_RBB_8_Q')}</div>
                            <div className="answer">{t('FAQ_RBB_8_A')}</div>
                        </div>
                    </div>}
                </div>
            </div>
            <div>
                <div className="group">
                    <div className="title">
                        <IconDocument/>
                        {t('MANUAL')}
                    </div>
                    <div>
                        <p>{t('MANUAL_DESCRIPTION')}</p>
                        <p style={{textAlign: 'right'}}>
                            {currentContext.type === 'rbb_dispatcher' ?
                                <a className="button"
                                href={process.env.PUBLIC_URL + "/manual/Ratunekbezbarier_Instrukcja_Dyspozytor_23.03.pdf"}
                                rel="noreferrer noopener" target="_blank">{t('DOWNLOAD_MANUAL', {size: '3.72'})}</a>
                            :
                                <a className="button"
                                href={process.env.PUBLIC_URL + "/manual/Click2open_Instrukcja_Zarzadca_23.03.pdf"}
                                rel="noreferrer noopener" target="_blank">{t('DOWNLOAD_MANUAL', {size: '2.73'})}</a>
                            }
                        </p>
                    </div>
                </div>
                <div className="group">
                    <div className="title">
                        <IconSupport/>
                        {t('SUPPORT')}
                    </div>
                    <div>
                        <p>{t('SUPPORT_DESCRIPTION')}</p>
                        <dl>
                            <dt>{t('EMAIL')}</dt>
                            <dd><a href="mailto:info@treesat.io">info@treesat.io</a></dd>
                            <dt>{t('MESSENGER')}</dt>
                            <dd><a href="///m.me/treesat.io" target="_blank" rel="noopener noreferrer">m.me/treesat.io</a></dd>
                            <dt>{t('PHONE')}</dt>
                            <dd><a href="tel:+48898881016">+48 89 888 10 16</a></dd>
                        </dl>
                    </div>
                </div>
                <div className="group">
                    <div className="title">
                        <IconChevronDoubleUp/>
                        {t('SYSTEM_INFO')}
                    </div>
                    <div>
                        <dl>
                            <dt>{t('VERSION')}</dt>
                            <dd>{version}</dd>
                            <dt>{t('CHANGE_LOG')}</dt>
                            <dd><span onClick={showChangeLog} className="text-only button"><IconDocumentMulti/>{t('SHOW')}</span></dd>
                        </dl>
                    </div>
                </div>
            </div>
        </main>
    </>
}
