import changes from "../../changelog.json";


/**
 *
 * @param {string} fromVersion
 * @param {string} toVersion
 */
export default function extractChanges(fromVersion, toVersion) {
    let from = fromVersion.replace('v', '').split('.').map(v => parseInt(v));
    let to = toVersion.replace('v', '').split('.').map(v => parseInt(v));
    if (from.length !== 3) {
        from = [0, 0, 0];
    }
    console.debug('extractChanges() => ', from, to);

    let ret = [];

    for (let major = from[0]; major <= to[0]; major++) {
        if (!changes[major]) {
            continue;
        }
        console.debug('extractChanges("%s", "%s") => major: ', fromVersion, toVersion, major);

        let minors = Object.keys(changes[major]).map(v => parseInt(v));

        if (major === from[0]) {
            minors = minors.filter(v => v >= from[1]);
        }
        if (major === to[0]) {
            minors = minors.filter(v => v <= to[1]);
        }

        for (let minor of minors) {
            console.debug('extractChanges("%s", "%s") => v: ', fromVersion, toVersion, major+'.'+minor);

            let patches = Object.keys(changes[major][minor]).map(v => parseInt(v));
            if (major+'.'+minor === from[0]+'.'+from[1] && from[0]+'.'+from[1] !== to[0]+'.'+to[1]) {
                patches = patches.filter(v => v > from[2]);
            }
            if (major+'.'+minor === to[0]+'.'+to[1] && from[0]+'.'+from[1] !== to[0]+'.'+to[1]) {
                patches = patches.filter(v => v <= to[2]);
            }

            for (let patch of patches) {
                console.debug('extractChanges("%s", "%s") => v: ', fromVersion, toVersion, major+'.'+minor+'.'+patch);
                ret.push({
                    version: major+'.'+minor+'.'+patch,
                    changes: changes[major][minor][patch]
                })
            }
        }
    }
    return ret;
}
