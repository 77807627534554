import React, {Component, FormEvent, FormEventHandler, MutableRefObject, ReactNode} from "react";
// import ReactTooltip from "react-tooltip";
import i18n from "../../i18n"

import "./RightPane.scss";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

interface RightPaneProps {
    id: string;
    className?: string;
    title: string;
    footer?: ReactNode;
    buttons?: ReactNode;
    onShow?: Function;
    onHide?: Function;
    onSubmit?: Function<FormEventHandler>;
    ref?: MutableRefObject<>
}
export default class RightPane extends Component<RightPaneProps> {
    constructor(props) {
        super(props);
        let {id, onShow, onHide} = props

        this.id = id;
        if (!this.id) {
            throw new Error('RightPane requires "id" property to be set');
        }

        // this.onComponentVisible = onShow;
        // this.onComponentHidden = onHide;

        this.state = {
            visible: false,
            onComponentVisible: onShow,
            onComponentHidden: onHide,
        }
    }

    componentDidMount(): void {
        setTimeout(this.showComponent, 10);
    }

    showComponent = () => {
        this.setState({visible: true});
    };

    hideComponent = () => {
        this.setState({visible: false});
    };

    transitionEnd = (e: TransitionEvent) => {
        if (e.propertyName !== 'transform') {
            return;
        }
        this.state.visible && this.state.onComponentVisible && this.state.onComponentVisible();
        !this.state.visible && this.state.onComponentHidden && this.state.onComponentHidden();
    };

    render() {
        let {children, footer, title, buttons, className, onSubmit} = this.props;
        const _onSubmit = onSubmit ? onSubmit : (e: FormEvent) => {e.preventDefault(); console.warn('RightPane::[submit] => event prevented!')};
        return (
            <form id={this.id} className={"RightPane" + (this.state.visible ? " visible" : "")} onSubmit={_onSubmit} onClick={this.hideComponent}>
                <span className="coat"/>
                <div className={"window " + className} onTransitionEnd={this.transitionEnd} onClick={(e) => {e.stopPropagation()}}>
                    {/*<ReactTooltip effect="solid" />*/}
                    <div className="header">
                        <span className={"close"} data-tip={i18n.t('CLOSE')} data-place={"left"} onClick={this.hideComponent}>&times;</span>
                        <span className="title">{title}</span>
                        {buttons}
                    </div>
                    <div className="body">{children}</div>
                    {footer ? (<div className="footer">{footer}</div>) : null}
                </div>
            </form>
        );
    }
}
interface TextFieldProps {
    id: string;
    label: string;
    value: string;
    name: string;
    onChange: Function<InputEvent>;
    hasError?: boolean;
    type?: string;
    disabled?: boolean;
    required?: boolean;
    hint?: string;
}
interface NumberFieldProps extends TextFieldProps {
    min?: number;
    max?: number;
    step?: number;
}
interface SelectFieldProps extends TextFieldProps {
    options: Array<{disabled?: boolean, label: string, value: mixed}>,
    createAllowed?: boolean,
    createLabel?: Function<string>,
    handleCreate?: Function<string>,
    isValidNewOption?: Function<string>,
    isSearchable?: boolean
}
export function TextField(props: TextFieldProps) {
    let _type = "text";
    if (props.type) {
        _type = props.type;
    }
    return <div ref={props.innerRef} className={"field" + (props.required ? " required" : "") + (props.hasError ? " error" : "")}>
        <label htmlFor={props.id}>{props.label}</label>
        <input type={_type} id={props.id} value={props.value} name={props.name} onChange={props.onChange} disabled={props.disabled}/>
    </div>
}
export function NumberField(props: NumberFieldProps) {
    return <div className={"field" + (props.required ? " required" : "") + (props.hasError ? " error" : "")}>
        <label htmlFor={props.id}>{props.label}</label>
        <input type="number" id={props.id} value={props.value} name={props.name} onChange={props.onChange} min={props.min} max={props.max} step={props.step} disabled={props.disabled}/>
    </div>
}
export function SelectField(props: SelectFieldProps) {
    let ActualSelect = props.createAllowed ? CreatableSelect : Select;
    return <div className={"field" + (props.required ? " required" : "") + (props.hasError ? " error" : "")} ref={props.innerRef}>
        <label htmlFor={props.id}>{props.label}</label>
        <ActualSelect className="react-select" classNamePrefix="react-select"
                      options={props.options !== null && props.options.map(o => ({...o, isDisabled: o.disabled}))}
                      placeholder={props.label}
                      value={(props.value === null || props.value === '') ? null : (props.options !== null && props.options.find(o => o.value === props.value))}
                      onChange={(o: SelectFieldOption) => props.onChange({target: {name: props.name, value: o?.value}})}
                      isDisabled={!!props.disabled}
                      isClearable={props.createAllowed || !props.required}
                      isSearchable={props.isSearchable ?? true}

                      createOptionPosition="first"
                      formatCreateLabel={props.createLabel}
                      onCreateOption={props.handleCreate}
                      isValidNewOption={props.isValidNewOption}
        />
        {props.hint && <span className="hint">{props.hint}</span>}
        {/*<select id={props.id} value={props.value} name={props.name} onChange={props.onChange}>*/}
        {/*    {props.options.map(o => <option key={props.id + '_' + o.value} value={o.value} disabled={o.disabled}>{o.label}</option>)}*/}
        {/*</select>*/}
    </div>
}

export interface SelectFieldOption {
    value: string;
    label: string;
    disabled?: boolean;
}
